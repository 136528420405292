import { Controller } from 'stimulus'

export default class extends Controller {
  open({ params: { acceptButtonId, actionUrl, elementId } }) {
    const modal = document.querySelector(`#${elementId}`)
    const modalAcceptButton = document.querySelector(`#${acceptButtonId}`)

    $(modal).modal()

    modalAcceptButton.setAttribute('href', actionUrl)
  }
}
