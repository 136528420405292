import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.publicSurveySpinner = document.getElementById('public_survey_spinner');
  }

  downloadResultsPdf(e) {
    const button = e.currentTarget;
    e.preventDefault();
    this.publicSurveySpinner.classList.toggle('hidden');
    button.classList.add('hidden');

    fetch(e.currentTarget.dataset.path, {
      headers: { redirect_on_error: 'false' }
    })
      .then(async (response) => {
        if (!response.ok) {
          const errorMessage = await response.text();
          throw new Error(errorMessage);
        }
        return response.blob();
      })
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = button.dataset.filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }).catch(error => {
        triggerAlert(error.message);
        allow_multiple_alerts();
      }).finally(() => {
        this.publicSurveySpinner.classList.toggle('hidden');
        button.classList.remove('hidden');
      })
  }
}