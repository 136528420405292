import { Controller } from "@hotwired/stimulus";
import I18n from "i18n-js";

export default class extends Controller {
  static targets = ["duration", "unit", "error", "durationWrapper","guestCodeValidity"];

  static MAX_DURATION = {
    days: 30,
    months: 6
  };

  static UNITS = {
    days: "0",
    months: "1"
  };

  connect() {
    this.setDefaultMessage();
    this.updateVisibility();
  }

  onDurationOrUnitChange() {
    this.validate();
  }

  validate() {
    let duration = parseInt(this.durationTarget.value, 10);
    let unit = this.unitTarget.value; 

    this.clearErrors(); 

    if (isNaN(duration) || duration <= 0){
      this.durationTarget.value = 1;
    } 

    if (unit === this.constructor.UNITS.days && duration > this.constructor.MAX_DURATION.days) {
      this.showError(I18n.t("errors.guest_registry_shared_qr.days"));
    } else if (unit === this.constructor.UNITS.months && duration > this.constructor.MAX_DURATION.months) {
      this.showError(I18n.t("errors.guest_registry_shared_qr.months"));
    }
  }

  clearErrors() {
    this.durationTarget.classList.remove("ca-form-error-border");
    this.setDefaultMessage();
  }

  showError(message) {
    this.durationTarget.classList.add("ca-form-error-border");
    this.errorTarget.classList.add("ca-invalid-feedback");
    this.errorTarget.textContent = message;
  }

  onGuestCodeValidityChange() {
    this.updateVisibility();
  }

  updateVisibility() {
    const guestCodeValidityValue = this.guestCodeValidityTarget.value;

    if (guestCodeValidityValue === "1") {
      this.durationWrapperTarget.style.display = "none";
    } else {
      this.durationWrapperTarget.style.display = "block";
      this.setDefaultMessage();
    }
  }

  setDefaultMessage() {
    this.errorTarget.textContent = I18n.t('errors.guest_registry_shared_qr.default_message');
    this.errorTarget.classList.remove("ca-invalid-feedback");
  }
}
