import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    shareUrl: String,
    redirectUrl: String,
    flashUrl: String
  }

  openShareUrl(event) {
    event.preventDefault()
    window.open(this.shareUrlValue, "_blank")
    window.$('#share_expert_administrator_certificate').modal('hide')
    setTimeout(() => {
      this.doTurboRequest(this.flashUrlValue)
    }, 100)
  }


  doTurboRequest(url) {
    fetch(url, {
      headers: {
        Accept: "text/vnd.turbo-stream.html"
      },
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text();
    })
    .then((html) => {
      Turbo.renderStreamMessage(html)
    }).catch((error) => {
      this.element.disabled = false
    })
  }
}
