import { Controller } from "stimulus";
import I18n from 'i18n-js';

export default class extends Controller {
  static targets = ['firstSource', 'secondSource', 'thirdSource', 'toFill', 'discountType', 'amountLabel','currencyType'];

  connect(){
    const discountType = this.discountTypeTarget?.value;

    this.setAmountLabel(discountType);
  }

  fillBlankTarget() {
    const discountType = this.discountTypeTarget?.value;
    const currencyType = this.currencyTypeTarget.dataset.fillInputsCurrencyType;
    const firstSource = this.firstSourceTarget?.value;
    const secondSource = this.secondSourceTarget?.value.split('-');
    const thirdSource = this.thirdSourceTarget?.value.split('-');
    const formatedSecond = secondSource.length === 3 ? `${secondSource[2]}/${secondSource[1]}/${secondSource[0]}` : '';
    const formatedThird = thirdSource.length === 3 ? `${thirdSource[2]}/${thirdSource[1]}/${thirdSource[0]}` : '';
    let result = '';
    this.setAmountLabel(discountType);
    if (firstSource) {
      result += `${firstSource}`;
      if (discountType === 'PR') {
        result+= I18n.t('views.admin.package_discount.description.percentage');
      } else {
        result += ` ${currencyType} `;
      }
    }

    if (secondSource.length === 3) {
      result += `${I18n.t('views.admin.package_discount.description.from')} ${formatedSecond} `;
    }

    if (thirdSource.length === 3) {
      result += `${I18n.t('views.admin.package_discount.description.to')}  ${formatedThird}`;
    }

    this.toFillTarget.value = result;
  }

  setAmountLabel(discountType){
    if (discountType === 'PR') {
      this.amountLabelTarget.innerHTML = I18n.t('views.admin.package_discount.list.amount');
    } else {
      this.amountLabelTarget.innerHTML = I18n.t('views.admin.package_discount.list.fixed');
    }
  }
}
