import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['origin', 'destination'];

  connect() {
    this.syncHeight();
    new ResizeObserver(() => this.syncHeight()).observe(this.originTarget);
  }

  syncHeight() {
    if (this.hasOriginTarget && this.hasDestinationTarget) {
      this.destinationTarget.style.height = `${this.originTarget.offsetHeight}px`;
    }
  }
}
