import { Controller } from 'stimulus'
import I18n from 'i18n-js'

export default class extends Controller {
  static targets = ['submitButton', 'cashFlow', 'month', 'year', 'bankReconciliation', 'serviceBilling', 'serviceBillingLabel', 'income', 'incomeLabel']

  validateSubmitButton(event) {
    let checkboxes = document.querySelectorAll('.center-rows input[type=checkbox]')

    for (const checkbox of checkboxes) {
      if (checkbox.checked) {
        this.submitButtonTarget.disabled = false;
        return true;
      } else {
        this.submitButtonTarget.disabled = true;
      }
    };
  }

  async validatePeriodExpense() {
    let data = await this.getPeriodExpense()

    this.handleCashFlow(data.bank_reconciliation_closed)
    this.handleServiceBillings(data.service_billings_in_period)
    this.handlePayments(data.payments_in_period)
  }

  async getPeriodExpense() {
    let data = { month: this.monthTarget.value, year: this.yearTarget.value }
    let headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }

    const data_response = await fetch('/reports/no_period/period_expense',
      {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
      })

    return await data_response.json()
  }

  handleCashFlow(enable) {
    if (enable) {
      this.bankReconciliationTarget.disable = false
    } else {
      this.bankReconciliationTarget.disabled = true
      this.bankReconciliationTarget.checked = false
    }
    this.bankReconciliationTarget.dispatchEvent(new Event('change'))
  }

  handleServiceBillings(enable) {
    if (enable) {
      this.serviceBillingTarget.disabled = false
      this.serviceBillingLabelTarget.innerHTML = I18n.t('excels.balances.outcomes')
    } else {
      this.serviceBillingTarget.disabled = true
      this.serviceBillingTarget.checked = false
      this.serviceBillingLabelTarget.innerHTML = I18n.t('notice.reports.no_service_billings_found')
    }
    this.serviceBillingTarget.dispatchEvent(new Event('change'))
  }

  handlePayments(enable) {
    if (enable) {
      this.incomeTarget.disabled = false
      this.incomeLabelTarget.innerHTML = I18n.t('views.no_period_report.reports.income_by_charge')
    } else {
      this.incomeTarget.disabled = true
      this.incomeTarget.checked = false
      this.incomeLabelTarget.innerHTML = I18n.t('notice.reports.no_payments_found')
    }
    this.incomeTarget.dispatchEvent(new Event('change'))
  }
}
