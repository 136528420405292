import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["balanceHeader", "firstCell"];

  handleScroll() {
    const table = this.balanceHeader;
    const el1 = table.firstElementChild;
    const el2 = table.lastElementChild;
    const firstColumns = this.firstCellTargets;
    const noClass = !el1.classList.contains('shadow-col');

    if (this.elementsOverlap(el1, el2) && noClass) {
      firstColumns.forEach(firstColumn => firstColumn.classList.add("shadow-col"));
    } else if (!this.elementsOverlap(el1, el2) && !noClass) {
      firstColumns.forEach(firstColumn => firstColumn.classList.remove("shadow-col"));
    }
  }

  elementsOverlap(el1, el2) {
    const domRect1 = el1.getBoundingClientRect();
    const domRect2 = el2.getBoundingClientRect();

    return !(Math.floor(domRect1.right) <= domRect2.left);
  }

  // Getter for the balance header element
  get balanceHeader() {
    return this.element.querySelector(".scrollable-table > .scrollable-header > .row");
  }
}
