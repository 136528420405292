import { Controller } from "@hotwired/stimulus";
import I18n from "i18n-js";

export default class extends Controller {
  static values = {
    expandableIconClass: { type: String, default: '.action-icon' },
    boldParent: { type: Boolean, default: false }
  }


  static afterLoad() {
    const initializeRows = () => {
      document.querySelectorAll('[data-expanded="false"]').forEach(expandableRow => {
        if (expandableRow.id) {
          // Oculta las filas hijas por defecto si el ícono está en estado 'down'
          const childRows = document.querySelectorAll(`[data-parent-id='${expandableRow.id}']`);
          childRows.forEach(row => row.classList.add('hidden'));
        }
      });
    };

    // Espera a que el DOM esté completamente cargado
    if (document.readyState === 'loading') {
      document.addEventListener("DOMContentLoaded", initializeRows);
    } else {
      initializeRows();
    }
  }

  toggle(event) {
    const icon = event.currentTarget; // Ícono clicado
    const expandableRow = icon.closest('[data-expanded]'); // Fila expandible
    const rowId = expandableRow.id;

    // Determina si se deben ocultar las filas (según el estado del ícono)
    const shouldHide = icon.classList.contains('fa-chevron-up');

    this.toggleChildRows(rowId, shouldHide);
    this.changeIcon(icon, shouldHide);
    if (this.boldParentValue === true) {
      expandableRow.classList.toggle('total-sum')
      expandableRow.classList.toggle('white-background')
    }
  }

  toggleChildRows(rowId, shouldHide) {
    const childRows = this.getChildRows(rowId);
    childRows.forEach(row => {
      row.classList.toggle('hidden', shouldHide);

      // Verifica si la fila tiene hijos y aplica recursión
      const icon = row.querySelector(this.expandableIconClassValue);
      if (icon) {
        this.toggleChildRows(row.id, shouldHide);
        this.changeIcon(icon, shouldHide);
      }
    });
  }

  getChildRows(rowId) {
    return document.querySelectorAll(`[data-parent-id='${rowId}']`);
  }

  changeIcon(icon, shouldHide) {
    icon.classList.toggle('fa-chevron-up', !shouldHide);
    icon.classList.toggle('fa-chevron-down', shouldHide);
    const tooltipKey = shouldHide ? 'show' : 'hide';
    icon.dataset.title = I18n.t(tooltipKey);
    icon.dataset.originalTitle = I18n.t(tooltipKey);
  }
}
