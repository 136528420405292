import BaseController from './base_controller'

export default class extends BaseController {
  static values = {
    event: String,
    action: String
  }

  call() {
    let additionalInfo = {
      action: this.actionValue
    };
    trackEvent(this.eventValue, additionalInfo);
  }
}
